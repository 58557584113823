import React from "react";
import Author from "../General/Author";

const BlogAuthor = ({ name, excerpt, avatar, linkedin, title }) => {
  return (
    <section className="journal_content__text journal_content__author container py-5">
      <div className="h3 text-center mt-5">Written by</div>
      <hr className="w-25 border-primary border-thick mb-5" />
      <div className="row justify-content-center">
        <div className="col-md-11 col-lg-10">
          <Author
            name={name}
            bio={excerpt}
            avatar={avatar}
            linkedIn={linkedin}
            job={title}
          />
        </div>
      </div>
    </section>
  );
};

export default BlogAuthor;
