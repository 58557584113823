import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Newsletter from "../components/General/Newsletter";

import BlogHeader from "../components/Blog/BlogHeader";
import OldContent from "../components/Blog/OldContent";
import BlogAuthor from "../components/Blog/BlogAuthor";

// import BlogSection from '../components/Blog/BlogSection'

import Sidebar from "../components/Blog/Sidebar";
import useSiteMetadata from "../components/SiteMetadata";
import Seo from "../components/General/Seo";

import BlogNext from "../components/Blog/BlogNext";

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { meta, social } = useSiteMetadata();

  var author = { name: "", title: "", featuredimage: "", linkedIn: "" };

  for (var i = 0; i < data.authors.edges.length; i++) {
    if (
      data.authors.edges[i].node.frontmatter.name === post.frontmatter.author
    ) {
      author = data.authors.edges[i].node.frontmatter;
    }
  }

  return (
    <Layout>
      <Seo
        title={
          post.frontmatter.title +
          meta.sitePageTitleSep +
          "Blog" +
          meta.sitePageTitleSep +
          meta.siteTitle
        }
        slug={
          post.frontmatter.slug
            ? meta.siteUrl + post.frontmatter.slug
            : meta.siteUrl + post.fields.slug
        }
        description={
          post.frontmatter.metaDescription
            ? post.frontmatter.metaDescription
            : post.frontmatter.excerpt
            ? post.frontmatter.excerpt
            : meta.blogDescription
        }
        image={
          meta.siteUrl +
          (post.frontmatter.metaImage
            ? post.frontmatter.metaImage.publicURL
            : post.frontmatter.image && post.frontmatter.image.publicURL
            ? post.frontmatter.image.publicURL
            : meta.siteImage)
        }
        twitterTitle={post.frontmatter.title}
        facebookTitle={post.frontmatter.title}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
      />

      <BlogHeader
        title={post.frontmatter.title}
        excerpt={post.frontmatter.excerpt}
        category={``}
        date={post.frontmatter.date}
        authorName={author.name}
        authorExcerpt={author.excerpt}
        authorAvatar={author.featuredimage}
        authorLinkedin={author.linkedin}
        authorTitle={author.title}
        readtime={post.timeToRead}
        bg_colour={`bg-light`}
        bg_image={``}
        featured_media={``}
        textColour={`text-secondary`}
      />
      <main id="main" className={`journal_content journal-`} data-aos="fade-up">
        <Sidebar
          title={post.frontmatter.title}
          url={`${meta.siteUrl}${post.fields.slug}`}
        />
        <OldContent content={post.html} />
      </main>

      {/* <BlogSection category={category} /> */}

      {author.name && (
        <BlogAuthor
          name={author.name}
          excerpt={author.excerpt}
          avatar={author.featuredimage}
          linkedin={author.linkedin}
          title={author.title}
        />
      )}
      <BlogNext posts={data.additionalPosts.edges} />

      <Newsletter
        title={social.blogNewsletterTitle}
        content={social.blogNewsletterContent}
        color={`secondary`}
        portalId={social.blogNewsletterPortalId}
        formId={social.blogNewsletterFormId}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        slug
        author
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
        }
        # tags
      }
      fields {
        slug
        # author {
        #   title
        #   name
        #   excerpt
        #   linkedin
        #   templateKey
        #   featuredimage {
        #     childImageSharp {
        #       fluid(maxWidth: 720) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
      }
    }

    authors: allMarkdownRemark(
      filter: { fields: { slug: { regex: "//authors//" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            name
            title
            excerpt
            featuredimage {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            linkedin
          }
        }
      }
    }

    additionalPosts: allMarkdownRemark(
      limit: 3
      filter: { id: { nin: [$id] }, fields: { slug: { regex: "//blog//" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
            excerpt
            image {
              publicURL
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
            # tags
          }
        }
      }
    }
  }
`;
