import React from "react";
import {
  FacebookShareButton,
  // LinkedinShareButton,
  TwitterShareButton,
  // GooglePlusShareButton,
  // WhatsappShareButton,
  // RedditShareButton,
} from "react-share";

const Sidebar = ({ title, url }) => {
  return (
    <aside className="journal_content__sidebar stick-lg-top pb-lg-5">
      <div className="container position-relative">
        <div
          className="journal_content__sidebar_inner pr-lg-4 "
          data-aos="fade-right"
          data-aos-anchor-placement="top-top"
        >
          <div
            className="h5 mt-5 mb-3 d-none d-lg-block"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="h5 mb-3 d-lg-none">Share this post:</div>
          <div className="socials pb-lg-5 d-block">
            <FacebookShareButton
              url={url}
              resetButtonStyle={true}
              className="mr-4 rounded"
            >
              <span className="icon">
                <i className="fab fa-facebook-f"></i>
              </span>
            </FacebookShareButton>
            <TwitterShareButton
              resetButtonStyle={true}
              className="mr-4 rounded"
              title={title}
              url={url}
              via={"databowl"}
              // hashtags={tags}
            >
              <span className="icon">
                <i className="fab fa-twitter"></i>
              </span>
            </TwitterShareButton>
            <a
              className="mr-4 rounded"
              title={title}
              onClick={(e) =>
                window.open(
                  "https://www.linkedin.com/sharing/share-offsite/?url=" + url,
                  "myWindow",
                  "width=800,height=600"
                )
              }
              href={"#LinkedInShare"}
            >
              <span className="icon">
                <i className="fab fa-linkedin-in text-primary hover-secondary"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
