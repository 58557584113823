import React from "react";
import { Link } from "gatsby";
import JournalCard from "./JournalCard";

const BlogNext = ({ posts }) => {
  return (
    <aside className="blog-more section mt-5">
      <h2 className="text-center my-5">More from the Blog</h2>

      <div className="container-fluid negative-margin ">
        <div className="row justify-content-center bg-light mt-5">
          <div className="col-lg-10 py-5 ">
            <div className="row negative-margin_inner justify-content-center contained-xl">
              {posts.map(({ node: post }, index) => (
                <div key={post.id} className="col-md-6 col-lg-4 mb-4">
                  <JournalCard
                    featured_media={post.frontmatter.image}
                    title={post.frontmatter.title}
                    date={post.frontmatter.date}
                    excerpt={post.frontmatter.excerpt}
                    slug={
                      post.frontmatter.slug
                        ? post.frontmatter.slug
                        : post.fields.slug
                    }
                    readtime={post.timeToRead}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row justify-content-center pb-5 bg-light">
          <Link to="/blog/" className="btn btn-outline-primary">
            More News
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default BlogNext;
